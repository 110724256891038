import * as Sentry from '@sentry/browser';

// Setup & Initialize Exception Notifier
export const setupExceptionHandler = (config = {}) => {
  if (process.env.REACT_APP_INVOICING_ENV !== 'development') {
    return Sentry.init({
      dsn: 'https://3c1c631cdf9a448182163d144edc3143@o126035.ingest.sentry.io/1462299',
      environment: process.env.REACT_APP_INVOICING_ENV,
      ignoreErrors: [
        // We are ignoring ResizeObserver loop limit exceeded errors because they are not actionable and are not causing any issues.
        // They stem from use of a 3rd-party widget.
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
      ],
      ...config,
    });
  }
};

// Manually throw exception errors
export const notify = (error, errorInfo = {}, errorLevel = 'error') => {
  const newError = error instanceof Error ? error : new Error(error);

  if (process.env.REACT_APP_INVOICING_ENV !== 'development') {
    // Send error to reporting service.
    Sentry.withScope((scope) => {
      scope.setLevel(errorLevel);

      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(newError);
    });
  }
};
